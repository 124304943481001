//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "home",
  methods: {},
  data() {
    return {};
  },
  head() {
    return {
      title: `${this.$store.state.appTitle} ${this.$store.state.appTitleDivider} Freelance creative coder`,
    };
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
  },
  methods: {},
  mounted() {
    this.$ga.page(this.$router);
  },
};
